body {
  margin: 0;
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.whitespace-pre {
    white-space: pre;
}

.navbar-brand-name {
    display: inline-block;
    position: relative;
    top: -14px;
    margin-left: .5rem;
    font-weight: 200;
}

.footer {
    position: relative;
    bottom: 0;
    width: 100%;
}

.main {
    min-height: calc(100vh - 163px);
}